import { defineStore } from 'pinia'

export const useFrontDataStore = defineStore('front', {
  state: () => {
    return { 
      menus: [],
      menu: {},
      adminMenu: {},
      adminMenuLoading: {},
      adminMenus: [],
      menusAreLoading: true,
      adminMenusAreLoading: true,
      pagesAreLoading: true,
      mediaObjectsAreLoading: true,
      mailTemplatesAreLoading: true,
      homeMenus: [],
      homeMenusAreLoading: true,
      order: {},
      orderIsLoading: false,
      orderDone: {},
      orderLoadError: '',
      pages: {},
      mediaObjects: {},
      mailTemplates: {},

      newsletterRegisterPopinDisplayed: false,
      chefCustomerConfig: {},
    };
  },
  getters:{
    isMenuIsLoading: (state) => { return (id) => (!state.menu[id]);},
    isAdminMenuIsLoading: (state) => { return (id) => (!state.adminMenu[id]);},
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    setFrontData(frontData) {
      this.frontData = frontData;
    },
    setHomeMenus(menus) {
      this.homeMenus = menus;
      this.homeMenusAreLoading = false;
    },
    setOrder(order) {
      this.order = order;
      this.orderIsLoading = false;
    },
    setOrderIsLoading(loading = true) {
      this.orderIsLoading = loading;
    },
    setOrderDone(orderDone) {
      this.orderDone = orderDone;
    },
    setOrderLoadError(orderLoadError) {
      this.orderLoadError = orderLoadError;
    },
    
    setMenus(menus) {
      this.menus = menus;
      this.menusAreLoading = false;
    },
    setMenu(menu) {
      this.menu[menu.id] = menu;
    },
    setAdminMenu(menu) {
      this.adminMenu[menu.id] = menu;
    },
    setAdminMenuLoading(id, loading) {
      this.adminMenuLoading[id] = loading;
    },
    setAdminMenus(menus) {
      this.adminMenus = menus;
      this.adminMenusAreLoading = false;
    },
    setAdminMenuOrderPayedBy(menuId, orderId, 
      payedInCashInCts, payedInCB1InCts, payedInCB2InCts,
      payedInCBTRInCts, payedInBankTransferInCts, payedInCheckInCtsInCts,
      payedInCB1LinkInCts, payedInCB2LinkInCts) {
      var menu = this.adminMenu[menuId];
      var orderIndex = menu.foodMenuOrders.findIndex((o) => o.id == orderId);
      this.adminMenu[menuId].foodMenuOrders[orderIndex].payedInCashInCts = payedInCashInCts;
      this.adminMenu[menuId].foodMenuOrders[orderIndex].payedInCB1InCts = payedInCB1InCts;
      this.adminMenu[menuId].foodMenuOrders[orderIndex].payedInCB2InCts = payedInCB2InCts;

      this.adminMenu[menuId].foodMenuOrders[orderIndex].payedInCBTRInCts = payedInCBTRInCts;
      this.adminMenu[menuId].foodMenuOrders[orderIndex].payedInBankTransferInCts = payedInBankTransferInCts;
      this.adminMenu[menuId].foodMenuOrders[orderIndex].payedInCheckInCtsInCts = payedInCheckInCtsInCts;

      this.adminMenu[menuId].foodMenuOrders[orderIndex].payedInCB1LinkInCts = payedInCB1LinkInCts;
      this.adminMenu[menuId].foodMenuOrders[orderIndex].payedInCB2LinkInCts = payedInCB2LinkInCts;
       //= menu;
    },
    setChefCustomerConfig(chefCustomerConfig) {
      this.chefCustomerConfig = chefCustomerConfig;
    },
    setPages(pages) {
      this.pages = pages;
      this.pagesAreLoading = false;
    },
    setMediaObjects(mediaObjects) {
      this.mediaObjects = mediaObjects;
      this.mediaObjectsAreLoading = false;
    },
    
    setMailTemplates(mails) {
      this.mailTemplates = mails;
      this.mailTemplatesAreLoading = false;
    },
    setMenusAreLoading(loading = true) {
      this.adminMenusAreLoading = loading;
    },
    setAdminMenusAreLoading(loading = true) {
      this.adminMenusAreLoading = loading;
    },
    setPagesAreLoading(loading = true) {
      this.pagesAreLoading = loading;
    },
    setMediaObjectsAreLoading(loading = true) {
      this.mediaObjectsAreLoading = loading;
    },
    setMailTemplatesAreLoading(loading = true) {
      this.mailTemplatesAreLoading = loading;
    },
    displayRegisterToNewsletterPopin() {
      this.newsletterRegisterPopinDisplayed = true;
    },
    hideRegisterToNewsletterPopin() {
      this.newsletterRegisterPopinDisplayed = false;
    },
  },
})