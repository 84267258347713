import { useAdminDataStore } from '../stores/admin';


const api = {
  ApiBaseUrl: `${process.env.VUE_APP_APIROOTURL}`,
  login(username, password) {
    return fetch(`${this.ApiBaseUrl}api/login?customer.id=${window.chefCustomerId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: username, password: password})
    })
    .then((response) => response.json())
    ;
  },
  
  getDiscountFor(email, phone) {
    return fetch(`${this.ApiBaseUrl}api/orderDiscount?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        customer: `/api/customers/${window.chefCustomerId}`,
        email, 
        phone
      })
    })
    .then((response) => response.json())
    ;
  },
  
  
  getOrder(orderId, shortCode) {
    return fetch(`${this.ApiBaseUrl}api/order?customer.id=${window.chefCustomerId}&id=${orderId}&shortCode=${shortCode}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    ;
  },
  
  cancelOrder(orderId, shortCode, sendEmailRecap) {
    const suffix = !sendEmailRecap ? '&sendEmailRecap=false' : '';
    return fetch(`${this.ApiBaseUrl}api/order?customer.id=${window.chefCustomerId}&id=${orderId}&shortCode=${shortCode}${suffix}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  getMenu(id) {
    return fetch(`${this.ApiBaseUrl}api/food_menus/${id}?customer.id=${window.chefCustomerId}&`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    ;
  },
  getAdminMenu(id) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menus/${id}?customer.id=${window.chefCustomerId}&`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listMenuCustomers() {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/menucustomers?customer.id=${window.chefCustomerId}&order[id]=asc&`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listMenus(filters) {
    return fetch(`${this.ApiBaseUrl}api/food_menus?customer.id=${window.chefCustomerId}&order[id]=desc&${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listAdminMenus(filters) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menus?customer.id=${window.chefCustomerId}&order[forDate]=desc&order[id]=desc&${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listPages(filters) {
    return fetch(`${this.ApiBaseUrl}api/pages?customer.id=${window.chefCustomerId}&order[id]=asc&${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    ;
  },
  listMediaObjects(filters, page) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/media_objects?customer.id=${window.chefCustomerId}&order[id]=asc&${filters}&page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listMailTemplates(filters) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/mail_templates?customer.id=${window.chefCustomerId}&order[id]=asc&${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json());
  },

  updateGeneric(modelName, id, toUpdateDatas) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/${modelName}/${id}?customer.id=${window.chefCustomerId}&`, {
      //method: 'PUT',
      method: 'PATCH',
      headers: {
        'Accept': 'application/ld+json',
        //'Content-Type': 'application/json',
        'Content-Type': 'application/merge-patch+json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({
        customer: `/api/customers/${window.chefCustomerId}`,
        ...toUpdateDatas,
      })
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  deleteGeneric(modelName, id) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/${modelName}/${id}?customer.id=${window.chefCustomerId}&`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    ;
  },
  
  createGeneric(modelName, toAddDatas) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/${modelName}?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({
        customer: `/api/customers/${window.chefCustomerId}`,
        ...toAddDatas,
      })
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },

  createMenu(toCreateDatas) {
    return this.createGeneric('food_menus', toCreateDatas);
  },
  updateMenu(id, toUpdateDatas) {
    return this.updateGeneric('food_menus', id, toUpdateDatas);
  },
  updateMenuItem(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_items', id, toUpdateDatas);
  },
  deleteMenuItem(id) {
    return this.deleteGeneric('food_menu_items', id);
  },
  createMenuItem(toAddDatas) {
    return this.createGeneric('food_menu_items', toAddDatas);
  },
  
  updateFoodMenuClient(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_clients', id, toUpdateDatas);
  },
  createFoodMenuClient(toCreateDatas) {
    return this.createGeneric('food_menu_clients', toCreateDatas);
  },
  
  updateMenuItemOption(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_item_options', id, toUpdateDatas);
  },
  deleteMenuItemOption(id) {
    return this.deleteGeneric('food_menu_item_options', id);
  },
  createMenuItemOption(toAddDatas) {
    return this.createGeneric('food_menu_item_options', toAddDatas);
  },
  updateMenuItemOptionDiscount(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_item_option_discounts', id, toUpdateDatas);
  },
  deleteMenuItemOptionDiscount(id) {
    return this.deleteGeneric('food_menu_item_option_discounts', id);
  },
  createMenuItemOptionDiscount(toAddDatas) {
    return this.createGeneric('food_menu_item_option_discounts', toAddDatas);
  },

  
  updateMenuTakeAway(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_take_aways', id, toUpdateDatas);
  },
  createMenuTakeAway(toAddDatas) {
    return this.createGeneric('food_menu_take_aways', toAddDatas);
  },
  
  updateMenuPackaging(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_packagings', id, toUpdateDatas);
  },
  createMenuPackaging(toAddDatas) {
    return this.createGeneric('food_menu_packagings', toAddDatas);
  },

  createOrder(toAddDatas) {
    return fetch(`${this.ApiBaseUrl}api/order?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...toAddDatas})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  updatePage(id, toUpdateDatas) {
    return this.updateGeneric('pages', id, toUpdateDatas);
  },
  updateMailTemplate(id, toUpdateDatas) {
    return this.updateGeneric('mail_templates', id, toUpdateDatas);
  },
  

  registerNewsletterRecipient(email) {
    return fetch(`${this.ApiBaseUrl}api/newsletter/suscribe?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, email: email})
    })
    .then((response) => response.json())
    ;
  },

  unregisterNewsletterRecipient(email) {
    return fetch(`${this.ApiBaseUrl}api/newsletter/unsuscribe?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, email: email})
    })
    .then((response) => response.json())
    ;
  },

  testNewsletter(email, foodMenuId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSend?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, email: email, foodMenuId: foodMenuId})
    })
    .then((response) => response.json())
    ;
  },  

  testMessageNewsletter(email, message, subject, button) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/message?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, email: email, message: message, subject: subject, button: button})
    })
    .then((response) => response.json())
    ;
  },  

  testNewsletterMultiMenu(email, foodMenuIds, subject, intro) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenusSend?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, email, foodMenuIds, subject, intro})
    })
    .then((response) => response.json())
    ;
  }, 
  newsletterMultiMenu(foodMenuIds, subject, intro) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenusSend?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, foodMenuIds, subject, intro})
    })
    .then((response) => response.json())
    ;
  },  
  messageNewsletter(message, subject, button) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/message?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, message: message, subject: subject, button: button})
    })
    .then((response) => response.json())
    ;
  },  

  testNewsletterLastChance(email, foodMenuId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSendLastChance?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, email: email, foodMenuId: foodMenuId})
    })
    .then((response) => response.json())
    ;
  },  

  testNewsletterReady(email, foodMenuId, foodMenuTakeAwayId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSendReady?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, email: email, foodMenuId: foodMenuId, foodMenuTakeAwayId})
    })
    .then((response) => response.json())
    ;
  },
  
  sendNewsletter(foodMenuId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSend?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, foodMenuId: foodMenuId})
    })
    .then((response) => response.json())
    ;
  },
  
  sendNewsletterLastChance(foodMenuId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSendLastChance?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, foodMenuId: foodMenuId})
    })
    .then((response) => response.json())
    ;
  },
  
  sendNewsletterReady(foodMenuId, foodMenuTakeAwayId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSendReady?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, foodMenuId: foodMenuId, foodMenuTakeAwayId: foodMenuTakeAwayId})
    })
    .then((response) => response.json())
    ;
  },
  
  listNewsletterRecipients(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter_recipients?customer.id=${window.chefCustomerId}&order[id]=asc&page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  
  
  listFoodMenuClients(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menu_clients?customer.id=${window.chefCustomerId}&order[id]=asc&page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  
  
  listFoodMenuOrders(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menu_orders?customer.id=${window.chefCustomerId}&order[id]=asc&page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  listFoodMenuItems(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menu_items?customer.id=${window.chefCustomerId}&order[id]=asc&page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  listFoodIngredients(page = 1, filters="") {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_ingredients?customer.id=${window.chefCustomerId}&page=${page}&order[modifiedAt]=desc&${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  addIngredient(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_ingredients?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    ;
  },
  editIngredient(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_ingredients/${id}?customer.id=${window.chefCustomerId}&`, {
      //method: 'PUT',
      method: 'PATCH',
      headers: {
        'Accept': 'application/ld+json',
        //'Content-Type': 'application/json',
        'Content-Type': 'application/merge-patch+json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    ;
  },

  
  listOrderDiscountRules(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/order_discount_rules?customer.id=${window.chefCustomerId}&order[id]=asc&page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  addOrderDiscountRule(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/order_discount_rules?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  editOrderDiscountRule(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/order_discount_rules/${id}?customer.id=${window.chefCustomerId}&`, {
      //method: 'PUT',
      method: 'PATCH',
      headers: {
        'Accept': 'application/ld+json',
        //'Content-Type': 'application/json',
        'Content-Type': 'application/merge-patch+json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  
  listFoodRecipes(page = 1, filters = "") {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipes?customer.id=${window.chefCustomerId}&page=${page}&order[modifiedAt]=desc&${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  addRecipe(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipes?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  editRecipe(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipes/${id}?customer.id=${window.chefCustomerId}&`, {
      //method: 'PUT',
      method: 'PATCH',
      headers: {
        'Accept': 'application/ld+json',
        //'Content-Type': 'application/json',
        'Content-Type': 'application/merge-patch+json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  addRecipeIngredient(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipe_ingredients?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  editRecipeIngredient(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipe_ingredients/${id}?customer.id=${window.chefCustomerId}&`, {
      //method: 'PUT',
      method: 'PATCH',
      headers: {
        'Accept': 'application/ld+json',
        //'Content-Type': 'application/json',
        'Content-Type': 'application/merge-patch+json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  addSubRecipe(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_sub_recipes?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  editSubRecipe(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_sub_recipes/${id}?customer.id=${window.chefCustomerId}&`, {
      //method: 'PUT',
      method: 'PATCH',
      headers: {
        'Accept': 'application/ld+json',
        //'Content-Type': 'application/json',
        'Content-Type': 'application/merge-patch+json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData})
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  deleteRecipeIngredient(id) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipe_ingredients/${id}?customer.id=${window.chefCustomerId}&`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  deleteSubRecipe(id) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_sub_recipes/${id}?customer.id=${window.chefCustomerId}&`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    ;
  },
  
  getFoodRecipe(id) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipes/${id}?customer.id=${window.chefCustomerId}&`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  uploadMediaObject(file, mediaType) {
    const adminStore = useAdminDataStore();
    
    const data = new FormData();
    data.append('file', file);
    data.append('customer', `/api/customers/${window.chefCustomerId}`);
    
    return fetch(`${this.ApiBaseUrl}api/media_objects?customer.id=${window.chefCustomerId}&media_type=${mediaType}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: data,
    })
    .then((response) => response.json())
    ;
  },
  
  orderSetPayed(id, data) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/orderSetPayed?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({
        customer: `/api/customers/${window.chefCustomerId}`,
        food_menu_order_id: id,
        ...data
      }),
    })
    ;
  },
  contactForm(formData) {
    return fetch(`${this.ApiBaseUrl}api/contact?customer.id=${window.chefCustomerId}&`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({customer: `/api/customers/${window.chefCustomerId}`, ...formData}),
    })
    .then((response) => response.json())
    ;
  },

};


export default api;