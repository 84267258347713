<template>
  <Header></Header>
  <div class="sm-main-content flex antialiased text-gray-800">
      <router-view></router-view>
  </div>
  
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <Footer></Footer>
  <NewsletterPopin></NewsletterPopin>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import NewsletterPopin from "./components/NewsletterPopin.vue";
import ConfirmDialog from 'primevue/confirmdialog';
import { useFrontDataStore } from './stores/front'
import { useAdminDataStore } from './stores/admin'
import get from './services/get';

export default {
  name: "App",
  components: {
    ConfirmDialog,
    Header,    
    Footer,
    NewsletterPopin,
},
  setup() {
    const frontDataStore = useFrontDataStore();
    const adminDataStore = useAdminDataStore();
    if(Object.keys(window.chefCustomerConfig).length == 0) {
      //https://projet-api.miam-vercors.com/api/customergetconfig?domain=projet-simon.miam-vercors.com
      
      if(window.chefCustomerId == 3) {
        // eslint-disable-next-line no-useless-escape
        window.chefCustomerConfig = {"id":3,"name":"Simon","withFront":false,"withOrder":false,"htmlReplacements":"","headerTitle":"Chef Orga","headerBaseline":"Test simon","facebookPageUrl":"https:\/\/www.facebook.com\/MInottos","instaPageUrl":"https:\/\/www.instagram.com\/ghislainbouilland\/","whatsappJoinLink":""};
      }else{
        // eslint-disable-next-line no-useless-escape
        window.chefCustomerConfig = {"id":1,"name":"miam-vercors","withFront":true,"withOrder":true,"htmlReplacements":"","headerTitle":"MIAM!","headerBaseline":"Cheffe nomade","facebookPageUrl":"https:\/\/www.facebook.com\/Miamvercors","instaPageUrl":"https:\/\/www.instagram.com\/miam_cheffe_nomade","whatsappJoinLink":"https:\/\/chat.whatsapp.com\/H4K5JosJ29XEeTtKXiNSWa"};
      }
    }
    
    if(document.location.host.indexOf('localhost') >= 0) {
        
        // Fonction pour vérifier si un paramètre existe (même vide)
        var hasParam = function(name) {
            const urlParams = new URLSearchParams(document.location.search);
            return urlParams.has(name);
        };

        // Fonction pour récupérer la valeur d'un paramètre (même vide)
        var getParam = function(name) {
            // Retourne null si le paramètre n'est pas présent
            const urlParams = new URLSearchParams(document.location.search);
            return urlParams.has(name) ? urlParams.get(name) : null;
        }
        Object.keys(window.chefCustomerConfig).forEach(function(k) {
          if(hasParam('__'+k)) {
            window.chefCustomerConfig[k] = getParam('__'+k);
          }
        });
        console.log(window.chefCustomerConfig);
      }

    frontDataStore.setChefCustomerConfig(window.chefCustomerConfig);

    get.getHomeMenus();
    get.listPages();
    
    const authToken = localStorage.getItem('authToken');
    const authTokenGeneratedAtTimestamp = parseInt(localStorage.getItem('authTokenGeneratedAtTimestamp'));
    if(authToken) {
      adminDataStore.setAuthToken(authToken, authTokenGeneratedAtTimestamp);
    }
  },
};

// contenu consigné +0
// emballage jetable + 1

</script>

<style lang="scss">
  @import './css/frontend.scss';
</style>
  