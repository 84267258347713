import { defineStore } from 'pinia'

export const useAdminDataStore = defineStore('admin', {
  state: () => {
    return { 
      authToken: '',
      authTokenGeneratedAtTimestamp: null,
      adminLastActiveIndex: 0,

      newsletterRecipients: [],
      newsletterRecipientsAreLoading: false,

      foodMenuClients: [],
      foodMenuClientsAreLoading: false,

      foodIngredients: [],
      foodIngredientsAreLoading: false,

      foodMenuItems: [],
      foodMenuItemsAreLoading: false,

      foodMenuOrders: [],
      foodMenuOrdersAreLoading: false,

      orderDiscountRules: [],
      orderDiscountRulesAreLoading: false,

      foodRecipes: [],
      foodRecipesAreLoading: false,

      addIngredientName: '',
      addIngredientUniverse: '',
      addIngredientPopinDisplayed: false,
      editedIngredient: null,

      editedOrderDiscountRule: null,
      addOrderDiscountRulePopinDisplayed: false,

      editedFoodMenuClient: null,
      addFoodMenuClientPopinDisplayed: false,
      
      popinPicturePickerDisplayed: false,
      popinPicturePickerSelectedPicture: '',


      addRecipePopinDisplayed: false,
      editedRecipe: null,
      editedRecipeOnlyShow: null,

      justAddedIngredient: null,
      justAddedOrderDiscountRule: null,
      justSavedRecipe: null,

      popinFormIsEdited: false,

      menuCustomersAreLoading: false,
      menuCustomers: [],
    }
  },
  getters:{
    isAuthenticated: (state) => (!!(state.authToken) && ((new Date().getTime() / 1000) - state.authTokenGeneratedAtTimestamp) < 3600 * 12),
  },
  actions: {
    setAuthToken(token, authTokenGeneratedAtTimestamp = null) {
      if(authTokenGeneratedAtTimestamp == null) {
        authTokenGeneratedAtTimestamp = Math.round((new Date().getTime() / 1000));
      }
      this.authToken = token;
      this.authTokenGeneratedAtTimestamp = authTokenGeneratedAtTimestamp;
    },    
    logout() {
      this.authToken = null;
      this.authTokenGeneratedAtTimestamp = null;
    },
    setAdminLastActiveIndex(index) {
      this.adminLastActiveIndex = index;
    },
    
    setNewsletterRecipients(newsletterRecipients) {
      this.newsletterRecipients = newsletterRecipients;
      this.newsletterRecipientsAreLoading = false;
    },
    setNewsletterRecipientsAreLoading(loading = true) {
      this.newsletterRecipientsAreLoading = loading;
    },
    setFoodMenuClients(foodMenuClients) {
      this.foodMenuClients = foodMenuClients;
      this.foodMenuClientsAreLoading = false;
    },
    setFoodMenuClientsAreLoading(loading = true) {
      this.foodMenuClientsAreLoading = loading;
    },
    setMenuCustomersAreLoading(loading = true) {
      this.menuCustomersAreLoading = loading;
    },
    setMenuCustomers(customers) {
      this.menuCustomers = customers;
      this.menuCustomersAreLoading = false;
    },    
    setFoodIngredients(foodIngredients) {
      this.foodIngredients = foodIngredients;
      this.foodIngredientsAreLoading = false;
    },
    setPopinPicturePickerDisplayed(popinPicturePickerDisplayed) {
      this.popinPicturePickerDisplayed = popinPicturePickerDisplayed;
    },
    setPopinPicturePickerSelectedPicture(url) {
      this.popinPicturePickerSelectedPicture = url;
    },
    setFoodIngredientsAreLoading(loading = true) {
      this.foodIngredientsAreLoading = loading;
    },
    setFoodMenuItems(foodMenuItems) {
      this.foodMenuItems = foodMenuItems;
      this.foodMenuItemsAreLoading = false;
    },
    setFoodMenuItemsAreLoading(loading = true) {
      this.foodMenuItemsAreLoading = loading;
    },
    setFoodMenuOrders(foodMenuOrders) {
      this.foodMenuOrders = foodMenuOrders;
      this.foodMenuOrdersAreLoading = false;
    },
    setFoodMenuOrdersAreLoading(loading = true) {
      this.foodMenuOrdersAreLoading = loading;
    },
    
    setOrderDiscountRules(orderDiscountRules) {
      this.orderDiscountRules = orderDiscountRules;
      this.orderDiscountRulesAreLoading = false;
    },
    setOrderDiscountRulesAreLoading(loading = true) {
      this.orderDiscountRulesAreLoading = loading;
    },
    setFoodRecipes(foodRecipes) {
      this.foodRecipes = foodRecipes;
      this.foodRecipesAreLoading = false;
    },
    deleteAFoodRecipe(id) {
      this.foodRecipes = this.foodRecipes.filter(f => f.id != id);
    },
    refreshFoodRecipe(foodRecipe) {
      
      const ix = this.foodRecipes.findIndex((r => r.id == foodRecipe.id));
      if(ix >= 0) {
        this.foodRecipes[ix] = {...foodRecipe};
      }else{
        this.foodRecipes = [foodRecipe, ...this.foodRecipes];
      }
      //this.foodRecipes = [...this.foodRecipes];
    },
    setFoodRecipe(id, foodRecipe) {
      var ix = this.foodRecipes.findIndex(r => r.id == id);
      if(ix >= 0) {
        this.foodRecipes[ix] = foodRecipe;
      }else{
        this.foodRecipes = [...this.foodRecipes, foodRecipe];
      }
    },
    refreshFoodIngredient(foodIngredient) {
      const ix = this.foodIngredients.findIndex((r => r.id == foodIngredient.id));
      if(ix >= 0) {
        this.foodIngredients[ix] = {...foodIngredient};
      }else{
        this.foodIngredients = [foodIngredient, ...this.foodIngredients];
      }
      //this.foodRecipes = [...this.foodRecipes];
    },
    setFoodIngredient(id, foodIngredient) {
      var ix = this.foodIngredients.findIndex(r => r.id == id);
      if(ix >= 0) {
        this.foodIngredients[ix] = foodIngredient;
      }else{
        this.foodIngredients = [...this.foodIngredients, foodIngredient];
      }
    },
    setFoodRecipesAreLoading(loading = true) {
      this.foodRecipesAreLoading = loading;
    },
    
    displayAddIngredientPopin(ingredientName) {
      this.editedIngredient = null;
      this.addIngredientPopinDisplayed = true;
      this.addIngredientName = ingredientName;
    },
    hideAddIngredientPopin() {
      this.addIngredientPopinDisplayed = false;
    },

    displayAddOrderDiscountRulePopin() {
      this.editedOrderDiscountRule = null;
      this.addOrderDiscountRulePopinDisplayed = true;
    },
    hideAddOrderDiscountRulePopin() {
      this.addOrderDiscountRulePopinDisplayed = false;
    },
    displayAddFoodMenuClientPopin() {
      this.editedFoodMenuClient = null;
      this.addFoodMenuClientPopinDisplayed = true;
    },
    hideAddFoodMenuClientPopin() {
      this.addFoodMenuClientPopinDisplayed = false;
    },
    
    displayEditIngredientPopin(editedIngredient) {
      this.editedIngredient = editedIngredient;
      this.addIngredientPopinDisplayed = true;
    },
    displayEditOrderDiscountRulePopin(orderDiscountRule) {
      this.editedOrderDiscountRule = orderDiscountRule;
      this.addOrderDiscountRulePopinDisplayed = true;
    },
    displayEditFoodMenuClientPopin(foodMenuClient) {
      this.editedFoodMenuClient = foodMenuClient;
      this.addFoodMenuClientPopinDisplayed = true;
    },

    
    displayAddRecipePopin() {
      this.editedRecipe = null;
      this.addRecipePopinDisplayed = true;
      this.editedRecipeOnlyShow = false;
    },
    hideAddRecipePopin() {
      this.addRecipePopinDisplayed = false;
    },
    
    setToEditRecipe(editedRecipe) {
      this.editedRecipe = editedRecipe;
    },
    
    displayEditRecipePopin(editedRecipe) {
      this.editedRecipe = editedRecipe;
      this.editedRecipeOnlyShow = false;
      this.addRecipePopinDisplayed = true;
    },

    displayShowRecipePopin(editedRecipe) {
      this.editedRecipe = editedRecipe;
      this.editedRecipeOnlyShow = true;
      this.addRecipePopinDisplayed = true;
    },

    setJustAddedIngredient(justAddedIngredient) {
      this.justAddedIngredient = justAddedIngredient;
    },
    setJustAddedOrderDiscountRule(orderDiscountRule) {
      this.justAddedOrderDiscountRule = orderDiscountRule;
    },
    setPopinFormIsEdited(popinFormIsEdited) {
      this.popinFormIsEdited = popinFormIsEdited;
    },

    setJustSavedRecipe(justSavedRecipe) {
      this.justSavedRecipe = justSavedRecipe;
      if(justSavedRecipe) {
        const ix = this.foodRecipes.findIndex((r) => r.id == justSavedRecipe.id);
        if(ix >= 0) {
          this.foodRecipes[ix] = justSavedRecipe;
        }
      }
    },
    
    
  },
})