<template>
  <footer class="p-4">    
    <div class="footer-inner">      
      
      <router-link 
      v-if="hasPartenaires"
      :to="{name: 'partenaires'}"
      class="block lg:inline-block lg:mt-0 text-teal-lighter"
      
      >
        Partenaires
      </router-link>

      <router-link 
      v-if="hasTermsAndConditions"
      :to="{name: 'termsAndConditions'}"
      class="block lg:inline-block lg:mt-0 text-teal-lighter"
      >
        Conditions générales de vente
      </router-link>

      
      <router-link 
      v-if="hasLegal"
      :to="{name: 'legal'}"
      class="block lg:inline-block lg:mt-0 text-teal-lighter"
      >
        Mentions légales
      </router-link>
      
      <router-link 
      v-if="hasAllergenes"
      :to="{name: 'allergenes'}"
      class="block lg:inline-block lg:mt-0 text-teal-lighter">
        Allergènes
      </router-link>
      
      <a 
      href="https://fr.linkedin.com/in/minotto"
      target="_blank"
      class="block lg:inline-block lg:mt-0 text-teal-lighter">
        Copyright {{ year }}
      </a>
    </div>
  </footer>
</template>

<script>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAdminDataStore } from '../stores/admin';
import { useFrontDataStore } from '../stores/front';

export default {
  name: "About",
  setup() {
    const adminDataStore = useAdminDataStore();
    const frontDataStore = useFrontDataStore();

    const { isAuthenticated } = storeToRefs(adminDataStore);

    const { pages } = storeToRefs(frontDataStore);
    const hasPartenaires = computed(() => (pages.value['partenaires'] || {}).descriptionHtml != '');    
    const hasTermsAndConditions = computed(() => (pages.value['termsAndConditions'] || {}).descriptionHtml != '');    
    const hasLegal = computed(() => (pages.value['legal'] || {}).descriptionHtml != '');    
    const hasAllergenes = computed(() => (pages.value['allergenes'] || {}).descriptionHtml != '');    


    return {
      isAuthenticated,
      hasPartenaires,
      hasTermsAndConditions,
      hasLegal,
      hasAllergenes,
      year: new Date().getFullYear(),
    };
  }
};
</script>