
export default function replaceHtml(cnt, withoutLineReturn = false) {
  if(!cnt) {
    return cnt;
  }
  let newCnt = cnt;
  newCnt = newCnt.replaceAll('<', '&lt;');
  newCnt = newCnt.replaceAll('>', '&gt;');
  if(!withoutLineReturn) {
    newCnt = newCnt.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }else{
    newCnt = newCnt.replace(/(?:\r\n|\r|\n)/g, ' ');
  }
  newCnt = newCnt.replaceAll(' 06 62 91 70 52', ' <a href="tel://0662917052">06 62 91 70 52</a>');
  newCnt = newCnt.replaceAll(' 06.62.91.70.52', ' <a href="tel://0662917052">06.62.91.70.52</a>');
  newCnt = newCnt.replaceAll('sophie@miam-vercors.com', ' <a href="mailto://sophie@miam-vercors.com">sophie@miam-vercors.com</a>');
  
  newCnt = newCnt.replaceAll(' menus de la semaine', ' <a href="/menu-de-la-semaine" class="jsGoToMenu">menus de la semaine</a>');
  newCnt = newCnt.replaceAll(' Menus de la semaine', ' <a href="/menu-de-la-semaine" class="jsGoToMenu">Menus de la semaine</a>');
  newCnt = newCnt.replaceAll(' menu de la semaine', ' <a href="/menu-de-la-semaine" class="jsGoToMenu">menu de la semaine</a>');
  newCnt = newCnt.replaceAll(' Menu de la semaine', ' <a href="/menu-de-la-semaine" class="jsGoToMenu">Menu de la semaine</a>');
  newCnt = newCnt.replaceAll(' menu du moment', ' <a href="/menu-de-la-semaine" class="jsGoToMenu">menu du moment</a>');
  newCnt = newCnt.replaceAll(' menus du moment', ' <a href="/menu-de-la-semaine" class="jsGoToMenu">menus du moment</a>');
  
  newCnt = newCnt.replaceAll(' me contacter', ' <a href="/me-contacter">me contacter</a>');

  newCnt = newCnt.replaceAll(' Comment ça marche', ' <a href="/comment-commander">Comment ça marche</a>');
  

  newCnt = newCnt.replaceAll('64 allée des peupliers, Lans-en-Vercors', ' <a href="https://www.google.fr/maps?q=64+all%C3%A9e+des+peupliers,+38250+Lans-en-Vercors" title="Afficher une carte" target="_blank">64 allée des peupliers, Lans-en-Vercors</a>');
  newCnt = newCnt.replaceAll(' MIAM!', ' <span style="text-transform: uppercase;font-family: &quot;Glacial Indifference&quot;, sans-serif;">MIAM!</span>');
  newCnt = newCnt.replaceAll("\n"+'MIAM!', "\n"+'<span style="text-transform: uppercase;font-family: &quot;Glacial Indifference&quot;, sans-serif;">MIAM!</span>');
  
  const normalizeImageNameForAlt=(src) => {
    return src.replaceAll('/images/medias/', '').replaceAll('-', ' ').replace('.jpg', '');
  };
  
  {
    // eslint-disable-next-line  no-invalid-regexp
    const re = new RegExp("\\[img\\.lightbox=([a-zA-Z0-9_./-]+)\\]", "g");
    const matches = newCnt.matchAll(re);


    [...matches].forEach((match) => {
      var src = match[1];
      var altSrc = match[1].replace('.jpg', '-full.jpg');
      const srcPath = src.indexOf('/images/medias/') === 0 ? `${process.env.VUE_APP_APIREMOTEROOTURL}` : '/illustrations/';
      newCnt = newCnt.replaceAll(match[0], `<img src="${srcPath}${src}" data-altsrc="${srcPath}${altSrc}" alt="${normalizeImageNameForAlt(src)}" class="lightbox-img" />`);
    });
  }
  {
    // eslint-disable-next-line  no-invalid-regexp
    const re = new RegExp("\\[img\\.lightbox.zoom=([a-zA-Z0-9_./-]+)\\]", "g");
    const matches = newCnt.matchAll(re);

    [...matches].forEach((match) => {
      var src = match[1];
      var altSrc = match[1].replace('.jpg', '-full.jpg');
      const srcPath = src.indexOf('/images/medias/') === 0 ? `${process.env.VUE_APP_APIREMOTEROOTURL}` : '/illustrations/';
      newCnt = newCnt.replaceAll(match[0], `<img src="${srcPath}${src}" data-altsrc="${srcPath}${altSrc}" alt="${normalizeImageNameForAlt(src)}" class="lightbox-img zoom" />`);
    });
  }
  
  return newCnt;
}