<template>
  <div class="sm-page-content" :class="[`sm-page-${pageShortname}`]" v-if="page">
    <h1 v-show="!hideTitle">{{pageTitle}}</h1>
    <slot name="beforeArticle"></slot>
    <article v-html="pageDescriptionHtml"></article>
    <slot></slot>
  </div>
  
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="imgsRef"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
</template>
<script>
  
import { computed, watch, ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useFrontDataStore } from '../stores/front';
import setMeta from '../misc/setMeta';

import VueEasyLightbox from 'vue-easy-lightbox'


export default {
  name: "GenericPage",
  props: {
    pageShortname: {
      type: String,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    descriptionHtml: {
      type: String,
    },
  },
  setup(props) {
    const frontDataStore = useFrontDataStore();
    const { pages } = storeToRefs(frontDataStore);
    const page = computed(() => pages.value[props.pageShortname] || {});    
    const router = useRouter();
    
    watch(page, function() {
      let metaTitle = page.value.metaTitle;
      let metaDescription = page.value.metaDescription;

      const pagePath = router.resolve({
        name: props.pageShortname, 
        params: {}
      }).fullPath;
      const routeFoodMenu = `${document.location.protocol}//${document.location.host}${pagePath}`;

      setMeta({
        title: metaTitle, 
        description: metaDescription, 
        canIndex: true,
        ogUrl: routeFoodMenu,
      });
    }, {deep: true, immediate: true});

    
    const visibleRef = ref(false)
    const indexRef = ref(0) // default 0
    const imgsRef = ref([]);
    
    visibleRef.value = false;

    imgsRef.value = [];
    indexRef.value = 0;

    const onHide = () => (visibleRef.value = false)
    const onPictureClicked = (evt) => {      
      //const src = evt.srcElement.src;
      const altSrc = evt.srcElement.getAttribute('data-altsrc');
      const ix = imgsRef.value.findIndex(i => i == altSrc);
      indexRef.value = ix;
      visibleRef.value = true;
    };

    const setImagesEvent = () => {
      const elms = document.querySelectorAll('.sm-page-prestations .lightbox-img,.lightbox-img.zoom');
      const imagesSrc = [...elms].map((e)=> e.getAttribute('data-altsrc'));
      [...elms].forEach(elm => {
        if(elm.classList.contains('inited')) {
          return;
        }
        elm.classList.add('inited');
        elm.addEventListener('click', onPictureClicked);
        elm.addEventListener('touch', onPictureClicked);
      });
      imgsRef.value = imagesSrc;
    }
    onMounted(() => {
      setTimeout(setImagesEvent, 100);
      setTimeout(setImagesEvent, 1000);
      setTimeout(setImagesEvent, 2000);
      setTimeout(setImagesEvent, 3000);
      setTimeout(setImagesEvent, 10000);
    });

    var pageTitle = computed(() => (props.title || page.value.title));
    var pageDescriptionHtml = computed(() => (props.descriptionHtml || page.value.descriptionHtml));
    
    watch(pageDescriptionHtml, () => {
      setTimeout(setImagesEvent, 100);
      setTimeout(setImagesEvent, 1000);
    });
    
    return {
      page: page,
      pageTitle,
      pageDescriptionHtml,
      visibleRef,
      indexRef,
      imgsRef,
      onHide,
      onPictureClicked,
    };
  },
  components: {
    VueEasyLightbox,
  },
};
</script>