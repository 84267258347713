<template>
  <GenericPage page-shortname="home" v-if="homePageFilled">    
    <template #beforeArticle>
      <div class="home-order-container">
        <Button class="">
          <router-link 
            :to="{name: 'currentMenu'}"
            >
              {{ homeMenus.length >= 2 ? 'Voir les menus de la semaine' : 'Voir le menu de la semaine' }}
            </router-link>
        </Button>
      </div>
    </template>
    <template v-if="custumerOrderOnOtherPage && orderableHomeMenus.length >= 1">
      <div class="home-order-container">
        <Button>
          <router-link 
            :to="{name: 'currentMenu'}"
            >
              {{ homeMenus.length >= 2 ? 'Voir les menus de la semaine' : 'Voir le menu de la semaine' }}
            </router-link>
        </Button>
      </div>
    </template>
  </GenericPage>

  <template v-if="custumerOrderOnHome">
    <ProgressSpinner v-if="homeMenusAreLoading"/>
    <template v-if="!homeMenusAreLoading">
      <div v-if="homeMenus.length == 0" class="sm-page-content">
        Il n'y a aucun menu disponible, revenez plus tard...
      </div>
      <FoodMenuPage 
        v-for="homeMenu in homeMenus"
        :key="homeMenu.id"
        :menuId="homeMenu.id"
        :class="{
          smHomeHidden: orderingMenuId != null && orderingMenuId != homeMenu.id,
          userCanOrder: homeMenu.userCanOrder,
          userCantOrder: !homeMenu.userCanOrder,
        }"
        :hasSeveralMenus="homeMenus.length >= 2"
        @loaded="onMenuLoaded"
        @ordering="onOrdering(homeMenu)"
        @notordering="onNotOrdering(homeMenu)"
        ></FoodMenuPage> 
    </template>
  </template>
  
</template>

<script>
import { storeToRefs } from 'pinia';
import { useFrontDataStore } from '../stores/front';
import { onUnmounted, onMounted } from 'vue';
import FoodMenuPage from './FoodMenuPage.vue';
import GenericPage from './GenericPage.vue';
import get from '../services/get';
import { watch, ref, computed } from 'vue';

export default {
  name: "Home",
  setup() {
    const frontDataStore = useFrontDataStore();
    const { homeMenus, homeMenusAreLoading } = storeToRefs(frontDataStore);   
    const { pages } = storeToRefs(frontDataStore);
    const homePage = computed(() => pages.value['home'] || {});
    const homePageFilled = computed(() => !!(homePage.value.title || homePage.value.description));

    const orderableHomeMenus = computed(() => {
      return homeMenus.value.filter(m => m.canOrder);
    });

    const { chefCustomerConfig } = storeToRefs(frontDataStore);
    //const custumerWithOrder = computed(() => chefCustomerConfig.value.withOrder);
    const custumerOrderOnHome = computed(() => chefCustomerConfig.value.withOrder && chefCustomerConfig.value.orderOnHome);
    const custumerOrderOnOtherPage = computed(() => chefCustomerConfig.value.withOrder && !chefCustomerConfig.value.orderOnHome);

    //const homeMenuId = computed(() => homeMenu.value.id);
    get.getHomeMenus();
    
    document.querySelector('html').classList.remove('miam-admin');

    let theReloadTimeout = null;
    let setHomeMenusClassesUInterval = null;

    const refreshHomeMenus = () => {
      get.getHomeMenus(true);
      theReloadTimeout = setTimeout(() => {refreshHomeMenus();}, 10*60*1000);
    };

    function getCoords(elem) { // crossbrowser version
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top  = box.top +  scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
    }

    function setHomeMenusClasses () {
      document.querySelectorAll('.sm-home').forEach((smHome) => {
        const rect = getCoords(smHome);
        //const rect = smHome.getBoundingClientRect();
        if(rect.top > 250) {
          smHome.classList.add('not-first-line');
        }else{
          smHome.classList.remove('not-first-line');
        }
      });
    }

    onUnmounted(() => {
      clearTimeout(theReloadTimeout);
      clearInterval(setHomeMenusClassesUInterval);
      window.removeEventListener('resize', setHomeMenusClasses);
    });

    onMounted(() => {
      setHomeMenusClasses();
    });

    theReloadTimeout = setTimeout(() => {refreshHomeMenus();}, 10*60*1000);
    setHomeMenusClassesUInterval = setInterval(setHomeMenusClasses, 1000);
    window.addEventListener('resize', setHomeMenusClasses);
    
    watch(homeMenusAreLoading, () => {
      setHomeMenusClasses();
      setTimeout(setHomeMenusClasses, 50);
    });
    const onMenuLoaded = () => {
      setHomeMenusClasses();
      setTimeout(setHomeMenusClasses, 50);
    };

    const orderingMenuId = ref(null);

    const onOrdering = (menu) => {
      orderingMenuId.value = menu.id;
    };
    const onNotOrdering = () => {
      orderingMenuId.value = null;
    };

    return {
      custumerOrderOnHome,
      homeMenusAreLoading,
      custumerOrderOnOtherPage,
      homeMenus,
      homePageFilled,
      orderingMenuId,
      orderableHomeMenus,
      onMenuLoaded,
      onNotOrdering,
      onOrdering,
    };
  },
  components: {
    FoodMenuPage,
    GenericPage,
  },
};
</script>